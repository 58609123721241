import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { fetchUsers } from '../database'; // Assume this function now returns users with their saved emotes
import { motion } from 'framer-motion';

export default function Home({ user }) {
  const headerDiv = useRef(null);
  const [users, setUsers] = useState([]);
  const recentCreations = useRef(null)

  useEffect(() => {
    const loadUsers = async () => {
      // Fetch up to 20 users from the database, including their saved emotes
      const fetchedUsers = await fetchUsers(20);
      let emoteChain = [];
      for (let i = 0; i < fetchedUsers.length; i++) {
        for (let j = 0; j < fetchedUsers[i].emotes.length; j++) {
          let user = {
            username: fetchedUsers[i].name,
            imageUrl: fetchedUsers[i].emotes[j].imageUrl
          }
          emoteChain.push(user);
        }
      }
      const emotes = await Promise.all(emoteChain);
      setUsers(emotes);
    };

    loadUsers();
  }, []);

  const drawImageToCanvas = (imageUrl, username, canvasElement) => {
    const ctx = canvasElement.getContext('2d');
    const img = new Image();
    img.crossOrigin = "Anonymous";  // This allows loading images from other domains
    img.onload = () => {
      // Set canvas size (adjust as needed)
      canvasElement.width = 200;
      canvasElement.height = 200;

      // Draw image at lower resolution
      ctx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);

      // Add username overlay
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fillRect(0, 0, canvasElement.width, canvasElement.height);
      ctx.fillStyle = 'white';
      ctx.font = '20px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(username, canvasElement.width / 2, canvasElement.height / 2);
    };
    img.src = imageUrl;
  };

  return (
    <div className='mx-auto mt-20 min-h-screen gap-40 grid justify-center align-center align-items-center'>
      <div ref={headerDiv} className="p-10"></div>
      <h6 className="typewriter text-2xl">
        KickLab
      </h6>
      <div className="w-full grid place-items-center justify-center align-center p-5">
        <div className=" px-4 sm:px-6 lg:px-8 flex justify-center items-center">
          <div className="flex flex-col items-center justify-center space-y-8">
            <div className="flex flex-col items-center justify-center gap-7">
              {user && (
                <div className="grid grid-cols-1 sm:flex sm:flex-row justify-center items-center gap-4 w-full max-w-md sm:max-w-none mx-auto">
                  <Link to="/generator" className="text-white bg-[#313131] px-5 py-3.5 rounded-[55px]">
                    <button style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <span className="group-hover:text-black transition-colors">Start Generating</span>
                    </button>
                  </Link>

                  <button
                    onClick={() => recentCreations.current.scrollIntoView({ behavior: 'smooth' })}
                    className="text-white bg-[#515151] py-3.5 px-5 rounded-[55px] flex items-center justify-center space-x-2 hover:bg-white hover:text-black transition group"
                  >
                    <span className="group-hover:text-black transition-colors">Recent Creations</span>
                  </button>
                </div>
              )}

              {!user && (
                <button className="text-white text-xl font-['Press Start 2P'] bg-black px-4 py-2 rounded-xl mt-10">Get Started</button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add this new section for the emote grid */}
      <div ref={recentCreations}></div>
      <div className="mt-20">

        <div className="px-4 py-7" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1 className="text-3xl font-bold text-center text-white">Recently Created</h1>
          <button
            onClick={() => headerDiv?.current.scrollIntoView({ behavior: "smooth" })}
            className="bg-white text-black p-1 p-1 rounded-md hover:bg-gray-100 transition-colors"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </button>
        </div>

        <div className='container mx-auto px-4'>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6">
            {users.map((emote, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="relative group"
              >
                <canvas
                  ref={(el) => {
                    if (el) {
                      drawImageToCanvas(emote.imageUrl, emote.username, el);
                    }
                  }}
                  className="rounded-lg w-full h-auto shadow-lg transition-shadow duration-300 group-hover:shadow-xl"
                ></canvas>
              </motion.div>
            ))}
          </div>
          <div className="p-10"></div>
        </div>
      </div>
    </div>
  );
}
