import React from 'react';
import { Link } from 'react-router-dom';

export default function GetStarted({ user }) {
  return (
    <div className="min-h-screen w-full grid place-items-center bg-slate py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto rounded-xl overflow-hidden md:max-w-2xl grid place-items-center gap-5 p-5">
        <h1 className="text-4xl font-bold text-white">Get Started</h1>
        <p className="text-lg text-white">
          Easily get started with our AI Emote Generator and AI Sub Badge Creator.
        </p>

        <div className="flex flex-col gap-2 p-4 rounded-lg">
          <div className="text-lg text-white text-left">Step 1</div>
          <h1 className="text-2xl font-bold text-white text-left">Sign in with Google to get started</h1>
          <p className="text-gray-400 text-left">By Signing in with Google, this allows you to save your prompts for later use.</p>

          <div className="text-lg text-white text-left">Step 2</div>
          <h1 className="text-2xl font-bold text-white text-left">Set your Kick username</h1>
          <p className="text-gray-400 text-left">This allows you to use the AI to generate emotes for your stream.</p>

          <div className="text-lg text-white text-center pt-10">
            <span role="img" aria-label="rocket" className="mr-2">🚀</span>
            That's it. You're ready to start using AI!
          </div>
        </div>

        {user ? (
          <div className="flex flex-col gap-2 bg-zinc-700 p-4 rounded-lg">
            <p className="text-lg text-white">
              You are logged in as {user.displayName}
            </p>

            <Link to="/profile">
              <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full text-xl transition duration-300 ease-in-out transform hover:scale-105">Start Using AI</button>
            </Link>
          </div>
        ) : (
          <div className="flex flex-col gap-2 bg-zinc-700 p-4 rounded-lg">
            <p className="text-lg text-white">
              You are not logged in.
            </p>

            <Link to="/">
              <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full text-xl transition duration-300 ease-in-out transform hover:scale-105">Sign in</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
