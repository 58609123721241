import { getFirestore, collection, query, limit as firestoreLimit, getDocs } from 'firebase/firestore';

export async function fetchUsers(limit = 20) {
  const db = getFirestore();
  const usersCollection = collection(db, 'users');
  const q = query(usersCollection, firestoreLimit(5));

  try {
    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map(doc => {
      const userData = doc.data();
      return {
        id: doc.id,
        name: userData.displayName,
        emotes: userData.emotes || [],
        // Add other user properties as needed
      };
    });
    return users;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
}