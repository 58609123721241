import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signInWithRedirect, getRedirectResult } from 'firebase/auth'; // Add signInWithRedirect import
import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { Toaster } from 'react-hot-toast';
import { Helmet } from "react-helmet";
import { motion, AnimatePresence } from 'framer-motion'; // Add this import


// Components
import Pricing from './components/Pricing';
import Profile from './components/Profile';
import GetStarted from './components/GetStarted';
import './styles/scrollbar.css';
import Generator from './components/Generator';
import Community from './components/Community';
import ImageEditor from './components/ImageEditor';
import Test from './components/Test';
import ConnectKick from './components/ConnectKick';
// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app); // Initialize Firestore

// PrivateRoute component
const PrivateRoute = ({ element, user }) => {
  return user ? element : <Navigate to="/" />; // Redirect to home if not logged in
};

function App() {
  const [user, setUser] = useState(null);
  const [userEmotes, setUserEmotes] = useState([]); // Add this line
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false); // Add this state

  // Add this new function to fetch updated user data
  const refreshUserData = async () => {
    if (user) {
      const userDoc = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        setUser({ ...user, ...userSnapshot.data() });
      }
    }
  };

  useEffect(() => {
    // Automatically sign in the user if they are already authenticated
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Check if user exists in Firestore
        const userDoc = doc(db, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUser({ ...currentUser, ...userData });
          setUserEmotes(userData.emotes || []); // Add this line
        }
      } else {
        setUser(null);
        setUserEmotes([]); // Clear emotes when user logs out
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []); // Empty dependency array to run once on mount

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user);

      // Check if user exists in Firestore
      const userDoc = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDoc);

      let userData;
      if (!userSnapshot.exists()) {
        // Save user profile if it doesn't exist
        userData = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          createdAt: new Date(),
          credits: 0,
        };
        await setDoc(userDoc, userData);
        console.log('User profile created in Firestore');
      } else {
        userData = userSnapshot.data();
        console.log('User profile fetched from Firestore');
      }

      // Set user state with Firestore data
      setUser({ ...user, ...userData });
    } catch (error) {
      console.error("Error during sign-in popup:", error);
    }
  };

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const user = result.user;
          console.log(user);
          setUser(user);

          // Check if user exists in Firestore
          const userDoc = doc(db, 'users', user.uid);
          const userSnapshot = await getDoc(userDoc);

          if (!userSnapshot.exists()) {
            // Save user profile if it doesn't exist
            await setDoc(userDoc, {
              displayName: user.displayName,
              email: user.email,
              photoURL: user.photoURL,
              createdAt: new Date(),
              credits: 0,
            });
            console.log('User profile created in Firestore');
          } else {
            console.log('User profile already exists in Firestore');
          }
        }
      } catch (error) {
        console.error("Error handling redirect result:", error);
      }
    };

    handleRedirectResult();
  }, []);

  const signOutGoogle = async () => {
    try {
      await auth.signOut();
      setUser(null);
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const saveKickUsername = async (userDoc) => {
    const kickUsername = prompt("Please enter your Kick username:"); // Prompt user for their Kick username
    if (kickUsername) {
      await setDoc(userDoc, { kickUsername }, { merge: true }); // Save username to Firestore
      console.log('Kick username saved to Firestore');
    }
  };

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Emote Ninja</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Toaster position="top-right" toastOptions={{ duration: 2000, className: "bg-zinc-600 text-white" }} />
      <Navbar clickSignOut={signOutGoogle} user={user} clickSignIn={signInWithGoogle} />

      <AnimatePresence>
        {isMessageBoxOpen && (
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            className="fixed bottom-0 right-0 bg-[#313131] rounded-t-[20px] shadow-lg w-80"
            style={{ zIndex: 1000 }}
          >
            {/* Header */}
            <div className="flex justify-between items-center p-4">
              <h3 className="text-lime-300 font-semibold">Kick AI Chat</h3>
              <button
                onClick={() => setIsMessageBoxOpen(false)}
                className="text-white-500 hover:text-lime-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </div>

            {user ? (
              <>
                {/* Messages Section */}
                <div className="h-80 overflow-y-auto p-4">
                  <div className="text-sm text-white bg-[#111111] rounded-lg p-4 font-[Calibri,_sans-serif]">
                    Chat feature coming soon.
                  </div>
                </div>

                {/* Input Section */}
                <div className="p-4">
                  <div className="flex gap-2">
                    <input
                      type="text"
                      placeholder="Type your message..."
                      className="flex-1 px-3 py-2 rounded-lg focus:ring-blue-500 bg-[#111111]"
                    />
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
                      Send
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="h-80 flex flex-col items-center justify-center p-4 text-center text-white">
                <p className="mb-4">Please sign in to use the chat feature</p>
                <button
                  onClick={signInWithGoogle}
                  className="bg-lime-500 text-black px-6 py-2 rounded-lg hover:bg-lime-600 transition-colors"
                >
                  Sign in with Google
                </button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        onClick={() => setIsMessageBoxOpen(!isMessageBoxOpen)}
        className="fixed bottom-0 right-10 max-w-[200px] min-w-[160px] bg-[#313131] text-white py-3 shadow-lg font-semibold rounded-t-[20px]"
        whileHover={{ backgroundColor: '#313131' }}
        whileTap={{ backgroundColor: '#313131' }}
        style={{ zIndex: 999 }}
      >
        <motion.span
          animate={{ rotate: isMessageBoxOpen ? 180 : 0 }}
          style={{ display: 'inline-block', marginRight: '8px' }}
        >
          ↑
        </motion.span>
        Kick AI Chat
      </motion.button>

      <Routes>
        <Route path="/" element={<Home user={user} />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/how-to-connect-kick" element={<ConnectKick />} />
        <Route path="/getstarted" element={<GetStarted user={user} />} />
        <Route path="/profile" element={<PrivateRoute element={<Profile saveKickUsername={saveKickUsername} />} user={user} />} />
        <Route path="/generator" element={
          <PrivateRoute
            element={
              <div style={{ marginTop: '100px', textAlign: 'center', fontSize: '2rem', color: 'gray', width: "100%" }}>
                <Generator userEmotes={userEmotes} refreshUserData={refreshUserData} />
              </div>
            }
            user={user}
          />
        } />
        <Route path="/community" element={<Community />} />
        <Route path="/image-editor" element={<ImageEditor emotes={userEmotes} user={user} />} />
        <Route path="/test" element={<div style={{ marginTop: '100px' }}><Test /></div>} />
        <Route path="*" element={<div style={{ paddingTop: '100px', textAlign: 'center', fontSize: '2rem', color: 'gray', width: "100%" }}>That page doesn't exist!</div>} />
      </Routes>

    </div>
  );
}

export default App;
