import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import axios from 'axios';

const christmasPrompts = [
  "cute christmas tree",
  "happy snowman face",
  "rudolph face",
  "striped candy cane",
  "gift box with bow",
  "smiling elf face",
  "happy gingerbread face",
  "glowing star",
  "golden bell",
  "santa face",
  "shiny bauble",
  "warm fireplace",
  "snowy cottage",
  "pine wreath",
  "red stocking",
  "steaming cocoa mug",
  "cute penguin face",
  "magical snow globe",
  "decorated cookie",
];

const negativePrompt = "no text, no numbers, no background, no border, racist, xenophobic, antisemitic, islamophobic, bigoted";

export const testFetch = await fetch("https://catfact.ninja/fact").then((response) => response.json()).then((data) => {
  return data;
});

export async function renderChristmasEmotes(count = 1) {
  try {
    const randomPrompt = christmasPrompts[Math.floor(Math.random() * christmasPrompts.length)];
    const basePrompt = `A TOK emoji of ${randomPrompt.toLowerCase()}`;

    const generate = await makeFetch("https://kick-ai-server-production.up.railway.app/api/generate-image", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        basePrompt,
        count,
        negativePrompt
      })
    });

    try {
      return await generate['replicate/classic'].items;
    } catch (error) {
      console.log(error)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function renderEmotes(prompt, refinementOption, count = 1) {
  const basePrompt = `A TOK emoji of ${prompt}, in ${refinementOption} style.`;

  const generate = await makeFetch("https://kick-ai-server-production.up.railway.app/api/generate-image", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      basePrompt,
      count,
      negativePrompt
    })
  });

  try {
    return await generate['replicate/classic'].items;
  } catch (error) {
    console.log(error)
  }
}

// export async function removeBackground(imageUrl) {
//   await makeFetch("http://localhost:3001/api/remove-background", {
//     method: "POST",
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       imageUrl
//     })
//   }).then((data) => {
//     console.log(data);
//     return data;
//   });
// }

async function makeFetch(url, options) {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    return await response.json().then((data) => {
      console.log(data);
      return data;
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const uploadImage = async (imageFile) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error('User not authenticated');
    return;
  }

  const storage = getStorage();
  const imageName = `${Date.now()}_${imageFile.name}`; // Unique file name
  const storageRef = ref(storage, `images/${user.uid}/${imageName}`);

  try {
    const snapshot = await uploadBytes(storageRef, imageFile);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('Image uploaded:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};