import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function ConnectKick() {
  const [code, setCode] = useState('')

  return (
    <div className="w-full max-w-2xl mx-auto mt-20 p-6 rounded-lg shadow-xl">
      <h1 className="text-center font-bold text-3xl text-white mb-8">Connect Your Kick Account</h1>

      <div className="space-y-6 text-gray-300">
        <p className="text-lg">
          To connect your Kick account, follow these simple steps:
        </p>

        <ol className="list-decimal list-inside space-y-4">
          <li>Open your Kick.com chat</li>
          <li>Type the following command:
            <code className="block bg-gray-700 p-2 rounded mt-2 text-green-400">
              !connect-kick YOUR_CODE
            </code>
          </li>
          <li>Replace <span className="font-semibold">YOUR_CODE</span> with the unique code provided below</li>
        </ol>

        <button
          className="bg-lime-500 w-full p-5 rounded-lg text-transform: uppercase text-black font-bold text-l flex items-center justify-center"
          onClick={() => window.open('https://kick.com/DevGwardo', '_blank', 'noopener,noreferrer')}
        >
          Visit Kick
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg>
        </button>

        <div className="grid gap-4">
          <Link to="/profile">
            <button className="bg-gray-700 text-white rounded-md px-4 py-2 w-full">
              Profile
            </button>
          </Link>

          <Link to="/generator">
            <button className="bg-gray-700 text-white rounded-md px-4 py-2 w-full">
              Create a Emote
            </button>
          </Link>
        </div>
        {/* <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-xl font-semibold mb-2">Your unique code:</p>
          <code className="text-2xl text-yellow-400">{code || 'Loading...'}</code>
        </div>

        <p className="italic">
          This code will expire in 5 minutes. If you need a new code, please refresh this page.
        </p> */}
      </div>
    </div>
  )
}
