import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { Camera } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import model from '../assets/nub_in_kitchen.glb';

// 3D Scene Component
const Scene = ({ isFetching }) => {
  const gltf = useLoader(GLTFLoader, model);
  const modelRef = useRef();

  useFrame((state) => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.005;
      if (isFetching) {
        modelRef.current.scale.x = THREE.MathUtils.lerp(modelRef.current.scale.x, 1.2, 0.1);
        modelRef.current.scale.y = THREE.MathUtils.lerp(modelRef.current.scale.y, 1.2, 0.1);
        modelRef.current.scale.z = THREE.MathUtils.lerp(modelRef.current.scale.z, 1.2, 0.1);
      } else {
        modelRef.current.scale.x = THREE.MathUtils.lerp(modelRef.current.scale.x, 1, 0.1);
        modelRef.current.scale.y = THREE.MathUtils.lerp(modelRef.current.scale.y, 1, 0.1);
        modelRef.current.scale.z = THREE.MathUtils.lerp(modelRef.current.scale.z, 1, 0.1);
      }
    }
    // Add some floating particles
    state.scene.children.forEach((child) => {
      if (child.isMesh && child.name === 'particle') {
        child.position.y += Math.sin(state.clock.elapsedTime + child.position.x) * 0.01;
      }
    });
  });

  useEffect(() => {
    // Add some floating particles to the scene
    for (let i = 0; i < 50; i++) {
      const particle = new THREE.Mesh(
        new THREE.SphereGeometry(0.05, 8, 8),
        new THREE.MeshBasicMaterial({ color: 0xffffff })
      );
      particle.position.set(
        Math.random() * 10 - 5,
        Math.random() * 10 - 5,
        Math.random() * 10 - 5
      );
      particle.name = 'particle';
      modelRef.current.add(particle);
    }
  }, []);

  return <primitive object={gltf.scene} ref={modelRef} />;
};

// Main Component
const AIChatComponent = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setMessages([...messages, { type: 'user', content: input }]);
    setInput('');
    setIsInitial(false);
    setIsFetching(true);

    try {
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      const response = "This is a simulated AI response.";
      setMessages(prev => [...prev, { type: 'ai', content: response }]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="fixed inset-x-0 top-[100px] bottom-0 bg-[#313131] text-white flex flex-col">
      <div className="absolute inset-0 z-0">
        <Canvas>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <Scene isFetching={isFetching} />
        </Canvas>
      </div>

      <div
        ref={chatContainerRef}
        className="relative z-10 flex-grow overflow-auto p-4 flex flex-col justify-end"
      >
        {isInitial ? (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex items-center justify-center h-full"
          >
            <form onSubmit={handleSubmit} className="w-full max-w-lg">
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Ask me anything..."
                className="w-full p-2 rounded bg-[#414141] text-white focus:outline-none"
              />
            </form>
          </motion.div>
        ) : (
          <div className="space-y-4">
            <AnimatePresence>
              {messages.map((message, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className={`p-2 rounded ${message.type === 'user' ? 'bg-[#414141]' : 'bg-[#515151]'}`}
                >
                  {message.content}
                </motion.div>
              ))}
            </AnimatePresence>
            {isFetching && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="p-2 rounded bg-[#515151]"
              >
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-white rounded-full animate-pulse"></div>
                  <div className="w-2 h-2 bg-white rounded-full animate-pulse delay-75"></div>
                  <div className="w-2 h-2 bg-white rounded-full animate-pulse delay-150"></div>
                </div>
              </motion.div>
            )}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>

      {!isInitial && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative z-10 p-4"
        >
          <form onSubmit={handleSubmit} className="flex items-center">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              placeholder="Type your message..."
              className="flex-grow p-2 rounded-l bg-[#414141] text-white focus:outline-none"
              disabled={isFetching}
            />
            <button type="submit" className="p-2 bg-[#515151] rounded-r" disabled={isFetching}>
              <Camera className="w-6 h-6" />
            </button>
          </form>
        </motion.div>
      )}
    </div>
  );
};

export default AIChatComponent;