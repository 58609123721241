import React, { useState, useEffect, useRef } from 'react';
import { Save, Download, Crop, Move, ZoomIn, ZoomOut, RotateCcw, RotateCw, Image as ImageIcon } from 'lucide-react';

const ImageEditor = ({ emotes, user }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [image, setImage] = useState(null);
  const [tool, setTool] = useState('move');
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [selectedEmote, setSelectedEmote] = useState(null);

  const canvasRef = useRef(null);
  const ctx = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      ctx.current = canvasRef.current.getContext('2d');
    }
  }, []);

  const loadImage = (url) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      // Convert image to base64
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const base64Image = canvas.toDataURL('image/png');

      setImage(img);
      setImageUrl(base64Image);
      if (canvasRef.current) {
        canvasRef.current.width = img.width;
        canvasRef.current.height = img.height;
        drawImage();
      } else {
        console.error('Canvas reference is not available');
      }
    };
    img.onerror = (error) => {
      console.error('Error loading image:', error);
    };
    img.src = url;
  };

  const drawImage = () => {
    if (!ctx.current || !image) return;

    ctx.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.current.save();
    ctx.current.translate(canvasRef.current.width / 2, canvasRef.current.height / 2);
    ctx.current.rotate((rotation * Math.PI) / 180);
    ctx.current.scale(zoom, zoom);
    ctx.current.drawImage(
      image,
      -image.width / 2,
      -image.height / 2,
      image.width,
      image.height
    );
    ctx.current.restore();

    if (tool === 'crop') {
      ctx.current.strokeStyle = 'white';
      ctx.current.strokeRect(crop.x, crop.y, crop.width, crop.height);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setImageUrl(event.target.result);
      loadImage(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleToolChange = (newTool) => {
    setTool(newTool);
    if (newTool === 'crop') {
      setCrop({ x: 0, y: 0, width: canvasRef.current.width / 2, height: canvasRef.current.height / 2 });
    }
  };

  const handleZoom = (direction) => {
    setZoom((prevZoom) => Math.max(0.1, prevZoom + direction * 0.1));
    drawImage();
  };

  const handleRotate = (direction) => {
    setRotation((prevRotation) => prevRotation + direction * 90);
    drawImage();
  };

  const handleSave = () => {
    // Implement save functionality (e.g., send to server)
    console.log('Saving image...');
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'edited-image.png';
    link.href = canvasRef.current.toDataURL();
    link.click();
  };

  const handleCrop = () => {
    if (tool !== 'crop') return;

    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = crop.width;
    croppedCanvas.height = crop.height;
    const croppedCtx = croppedCanvas.getContext('2d');

    croppedCtx.drawImage(
      canvasRef.current,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = croppedCanvas.toDataURL('image/png');
    const croppedImage = new Image();
    croppedImage.onload = () => {
      setImage(croppedImage);
      setImageUrl(base64Image);
      canvasRef.current.width = crop.width;
      canvasRef.current.height = crop.height;
      drawImage();
    };
    croppedImage.src = base64Image;
  };

  const addEmoteToCanvas = (emote) => {
    console.log(emote);
    console.log(ctx)
    if (!ctx.current) return;

    const emoteImg = new Image();
    emoteImg.crossOrigin = 'Anonymous';
    emoteImg.onload = () => {
      // Convert emote to base64
      const canvas = document.createElement('canvas');
      canvas.width = emoteImg.width;
      canvas.height = emoteImg.height;
      const tempCtx = canvas.getContext('2d');
      tempCtx.drawImage(emoteImg, 0, 0);
      const base64Emote = canvas.toDataURL('image/png');

      const x = (canvasRef.current.width - emoteImg.width) / 2;
      const y = (canvasRef.current.height - emoteImg.height) / 2;
      ctx.current.drawImage(emoteImg, x, y);
    };
    emoteImg.src = emote.url;
    console.log(emoteImg);
  };

  return (
    <div className="flex flex-col items-center p-4 justify-center align-middle">
      <input
        type="file"
        onChange={handleImageUpload}
        className="mb-4"
      />
      <div className="mb-4">
        <input
          type="text"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          placeholder="Enter image URL"
          className="border p-2 mr-2"
        />
        <button
          onClick={() => loadImage(imageUrl)}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Load Image
        </button>
      </div>
      <canvas
        ref={canvasRef}
        className="border border-gray-300 mb-4 min-w-[500px] min-h-[500px]"
      />
      <div className="flex space-x-2 mb-4">
        <button onClick={() => handleToolChange('move')} className={`p-2 ${tool === 'move' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
          <Move size={24} />
        </button>
        <button onClick={() => handleToolChange('crop')} className={`p-2 ${tool === 'crop' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
          <Crop size={24} />
        </button>
        <button onClick={() => handleZoom(1)} className="p-2 bg-gray-200">
          <ZoomIn size={24} />
        </button>
        <button onClick={() => handleZoom(-1)} className="p-2 bg-gray-200">
          <ZoomOut size={24} />
        </button>
        <button onClick={() => handleRotate(-1)} className="p-2 bg-gray-200">
          <RotateCcw size={24} />
        </button>
        <button onClick={() => handleRotate(1)} className="p-2 bg-gray-200">
          <RotateCw size={24} />
        </button>
        <button onClick={handleCrop} className="p-2 bg-gray-200">
          Apply Crop
        </button>
        <button onClick={handleSave} className="p-2 bg-gray-200">
          <Save size={24} />
        </button>
        <button onClick={handleDownload} className="p-2 bg-gray-200">
          <Download size={24} />
        </button>
      </div>
      <div className="flex flex-wrap justify-center gap-2 mb-4">
        {emotes.map((emote) => (
          <img
            key={emote.id}
            src={emote.imageUrl}
            alt={emote.name}
            className="w-12 h-12 cursor-pointer hover:border-2 hover:border-blue-500"
            onClick={() => {
              addEmoteToCanvas(emote);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageEditor;