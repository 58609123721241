import React, { useState, useRef } from 'react';
import { renderEmotes, renderChristmasEmotes, uploadImage } from '../server';
import { doc, updateDoc, arrayUnion, getFirestore, getDoc, runTransaction } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import toast from 'react-hot-toast';
import "../styles/Generator.css";
import Modal from './Modal'; // You'll need to create this component
import { FaPaintBrush, FaCamera, FaGamepad, FaPalette, FaPencilAlt, FaRegLightbulb, FaCube, FaRobot, FaClock, FaFire } from 'react-icons/fa';

export default function Generator({ refreshUserData, userEmotes }) {
  const scrollRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [refinementOption, setRefinementOption] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [generatedEmotes, setGeneratedEmotes] = useState([]);
  const [selectedEmote, setSelectedEmote] = useState(null);
  const [generationData, setGenerationData] = useState(null);
  const [generatedEmoteCount, setGeneratedEmoteCount] = useState(1);

  const confirmChristmasGeneration = async () => {
    setShowModal(false);
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      setLoading(false);
      return;
    }

    const db = getFirestore();
    const userDoc = doc(db, 'users', user.uid);

    try {
      // Get the current user's document
      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      const currentCredits = userData.credits || 0;

      if (currentCredits < 100 * generatedEmoteCount) {
        toast.error("Not enough credits");
        throw new Error('Not enough credits');
      }

      // Generate emotes with the new primaryColor parameter and count
      const results = await renderChristmasEmotes();

      // Deduct credits
      await updateDoc(userDoc, { credits: currentCredits - (100 * generatedEmoteCount) });
      toast.success(`${generatedEmoteCount} emote(s) generated successfully, credits deducted`);

      // Upload images and get URLs
      const uploadPromises = results.map(async (result) => {
        const response = await fetch(result.image_resource_url);
        const blob = await response.blob();
        const file = new File([blob], `emote_${Date.now()}.png`, { type: 'image/png' });
        return uploadImage(file);
      });

      const uploadedUrls = await Promise.all(uploadPromises);

      // Prepare new emotes array with uploaded URLs
      const newEmotes = uploadedUrls.map(url => ({
        prompt: "Christmas Emote",
        imageUrl: url,
        createdAt: new Date(),
      }));

      // Update user document with new emotes
      await updateDoc(userDoc, {
        emotes: arrayUnion(...newEmotes)
      });

      // Update state with new emotes
      setGeneratedEmotes(prevEmotes => [...prevEmotes, ...newEmotes]);

      // After successful generation and database update
      await refreshUserData();
      setPrompt("");
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log(`${generatedEmoteCount} emote(s) generated, uploaded, and saved, credits deducted`);
    } catch (error) {
      if (error.message === 'Not enough credits') {
        toast.error('Not enough credits to generate emote');
      } else if (error.message === "Server returned an error") {
        toast.error('An error occurred while generating the emote. Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      console.error('Error generating image:', error);
    } finally {
      setLoading(false);
      setGenerationData(null); // Reset generation data
    }
  };

  const confirmGeneration = async () => {
    setShowModal(false);
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      setLoading(false);
      return;
    }

    const db = getFirestore();
    const userDoc = doc(db, 'users', user.uid);

    try {
      // Get the current user's document
      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      const currentCredits = userData.credits || 0;

      if (currentCredits < 100 * generatedEmoteCount) {
        toast.error("Not enough credits");
        throw new Error('Not enough credits');
      }

      // Generate emotes with the new primaryColor parameter and count
      const results = await renderEmotes(prompt, refinementOption, generatedEmoteCount);

      console.log("results", results);

      // Deduct credits
      await updateDoc(userDoc, { credits: currentCredits - (100 * generatedEmoteCount) });
      toast.success(`${generatedEmoteCount} emote(s) generated successfully, credits deducted`);

      // Upload images and get URLs
      const uploadPromises = results.map(async (result) => {
        const response = await fetch(result.image_resource_url);
        const blob = await response.blob();
        const file = new File([blob], `emote_${Date.now()}.png`, { type: 'image/png' });
        return uploadImage(file);
      });

      const uploadedUrls = await Promise.all(uploadPromises);

      // Prepare new emotes array with uploaded URLs
      const newEmotes = uploadedUrls.map(url => ({
        prompt: prompt,
        imageUrl: url,
        createdAt: new Date(),
      }));

      // Update user document with new emotes
      await updateDoc(userDoc, {
        emotes: arrayUnion(...newEmotes)
      });

      // Update state with new emotes
      setGeneratedEmotes(prevEmotes => [...prevEmotes, ...newEmotes]);

      // After successful generation and database update
      await refreshUserData();
      setPrompt("");
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log(`${generatedEmoteCount} emote(s) generated, uploaded, and saved, credits deducted`);
    } catch (error) {
      if (error.message === 'Not enough credits') {
        toast.error('Not enough credits to generate emote');
      } else if (error.message === "Server returned an error") {
        toast.error('An error occurred while generating the emote. Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      console.error('Error generating image:', error);
    } finally {
      setLoading(false);
      setGenerationData(null); // Reset generation data
    }
  };

  const styleOptions = [
    { label: 'Realistic', value: 'realistic', icon: FaCamera },
    { label: 'Cartoon', value: 'cartoon', icon: FaPaintBrush },
    { label: 'Anime', value: 'anime', icon: FaPalette },
    { label: 'Pixel Art', value: 'pixel_art', icon: FaGamepad },
    { label: 'Watercolor', value: 'watercolor', icon: FaPaintBrush },
    { label: 'Sketch', value: 'sketch', icon: FaPencilAlt },
    { label: 'Pop Art', value: 'pop_art', icon: FaRegLightbulb },
    { label: 'Minimalist', value: 'minimalist', icon: FaCube },
    { label: 'Retro', value: 'retro', icon: FaClock },
    { label: 'Cyberpunk', value: 'cyberpunk', icon: FaRobot },
    { label: 'Halloween', value: 'halloween', icon: FaFire },
  ];

  const handleEmoteClick = (emote) => {
    setSelectedEmote(emote);
  };

  const handleCloseModal = () => {
    setSelectedEmote(null);
  };

  return (
    <div className="grid justify-center align-center gap-4 content-center overflow-y-auto">
      <h3 className="typewriter">
        Generate
      </h3>

      <div>
        <p className="text-base text-gray-500">Generate an emote based on your prompt.</p>
      </div>

      <div className="flex flex-col md:flex-row gap-4 justify-center">
        <div className="w-full md:w-[500px] md:min-w-[500px]">
          <div className="mb-4 items-center justify-center p-2 rounded-md w-full">
            <h2 className="text-2xl font-extrabold text-white mb-4 text-center">Generate an Emote</h2>
            <h4 className="text-sm font-extrabold text-gray-500 mb-4 text-center">Costs 100 credits per emote</h4>
            <div className="items-center w-full">
              <div className="text-sm" style={{ textTransform: "uppercase", textAlign: "left" }}>Prompt</div>
              <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="a silly blue cat"
                className="rounded-md text-base p-2 bg-zinc-800 w-full"
              />
              <div className="text-sm mt-2" style={{ textTransform: "uppercase", textAlign: "left" }}>Number of Emotes</div>
              <input
                type="number"
                id="emoteCount"
                value={generatedEmoteCount}
                onChange={(e) => setGeneratedEmoteCount(Math.max(1, parseInt(e.target.value)))}
                min="1"
                max="5"
                className="rounded-md text-base p-2 bg-zinc-800 w-full"
              />
              <div className="mt-6 flex align-center justify-center gap-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    confirmGeneration();
                  }}
                  className="w-[40%] bg-gray-500 text-white text-[20px] font-semibold rounded-md"
                  disabled={loading === true || prompt === '' || refinementOption === ''}
                >
                  Generate
                </button>

                <button
                  onClick={confirmChristmasGeneration}
                  className="w-full relative bg-[#313131] border-2 border-green-600 py-2 px-10 rounded-md text-white font-semibold 
                  hover:bg-[#404040] transition-all duration-300 
                  disabled:opacity-50 disabled:cursor-not-allowed text-[20px] w-[40%] christmas-button"
                >
                  {/* Christmas decorations */}
                  <div className="christmas-decorations">
                    <svg className="floating-svg bell" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 1.5c-3.5 0-6.5 2.5-6.5 6v3.5l-2 2v2h17v-2l-2-2v-3.5c0-3.5-3-6-6.5-6zm-2 17.5c0 1.1.9 2 2 2s2-.9 2-2h-4z" />
                    </svg>
                    <svg className="floating-svg gift" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20 6h-2.18c.11-.31.18-.65.18-1a2.996 2.996 0 0 0-5.5-1.65l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z" />
                    </svg>
                    <svg className="floating-svg star" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                  </div>
                  {/* Original tree SVG */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 inline-block mr-2"
                  >
                    <path d="M12 2L9 9H15L12 2Z" />
                    <path d="M12 6L8 14H16L12 6Z" />
                    <path d="M12 10L6 19H18L12 10Z" />
                    <rect x="10" y="19" width="4" height="3" />
                    <path d="M2 22H22" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-[400px] md:min-w-[400px]">
          <h2 className="text-2xl font-extrabold text-white mb-4 text-center">Generate an Emote</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {styleOptions.map((option) => (
              <button
                key={option.value}
                type="button"
                onClick={() => setRefinementOption(option.value)}
                className={`flex flex-col items-center justify-center p-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${refinementOption === option.value
                  ? 'bg-blue-600 text-white'
                  : 'bg-zinc-800 text-gray-300 hover:bg-zinc-600'
                  }`}
              >
                <option.icon className="text-2xl mb-2" />
                <span className="text-sm text-center">{option.label}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-zinc-800 rounded-lg p-6 max-w-sm w-full relative">
            <h3 className="text-lg font-semibold mb-4 text-white">Confirm Generation</h3>
            <p className="mb-4 text-lg">Are you sure you want to spend {100 * generatedEmoteCount} credits to generate {generatedEmoteCount} image(s)?</p>
            <p className="mb-4 text-sm bg-zinc-700 p-2 rounded-md">Prompt: {generationData?.prompt}</p>
            <p className="mb-4 text-sm bg-zinc-700 p-2 rounded-md">Style: {generationData?.refinementOption}</p>
            <div className="mb-4 flex items-center justify-center gap-2 bg-zinc-700 p-2 rounded-md">
              <span className="text-sm mr-2">Primary Color:</span>
              <div
                className="w-6 h-6 rounded-full"
                style={{ backgroundColor: generationData?.primaryColor }}
              ></div>
            </div>
            <div className="flex justify-center gap-5">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 text-sm p-3"
              >
                Cancel
              </button>
              <button
                onClick={confirmGeneration}
                className="px-4 py-2 bg-zinc-600 text-white rounded hover:bg-zinc-700 text-sm"
              >
                Confirm ({100 * generatedEmoteCount} Credits)
              </button>
            </div>
          </div>
        </div>
      )
      }

      {
        loading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-zinc-800 rounded-lg p-6 max-w-sm w-full text-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mx-auto mb-4"></div>
              <p className="text-white text-lg">Generating your emote(s)...</p>
            </div>
          </div>
        )
      }

      {
        generatedEmotes.length > 0 && (
          <>
            <div className="mt-4 p-4">
              <h2 className="text-center">Your generated emotes:</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
                {generatedEmotes.map((emote, index) => (
                  <div key={index} className="cursor-pointer grid grid-cols-1 max-w-[300px] text-pretty align-center justify-center bg-zinc-800 p-5 rounded-md hover:bg-zinc-700" onClick={() => handleEmoteClick(emote)}>
                    <img
                      src={emote.imageUrl}
                      alt={emote.prompt}
                      className="w-full h-auto rounded-lg"
                    />
                    <p className="text-center text-sm text-gray-500 mt-1 truncate p-2">{emote.prompt}</p>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ padding: ".25rem" }} ref={scrollRef}></div>
          </>
        )
      }

      {
        selectedEmote && (
          <Modal
            images={generatedEmotes}
            initialImageUrl={selectedEmote.imageUrl}
            prompt={selectedEmote.prompt}
            onClose={handleCloseModal}
          />
        )
      }

      <div style={{ padding: "1rem" }}></div>
    </div >
  );
}