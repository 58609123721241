import React, { useEffect, useState } from 'react'; // Added useState
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import logo from '../assets/Logo.png';

export default function Navbar({ clickSignIn, user, clickSignOut }) {
  const [scrollY, setScrollY] = useState(0); // Added state for scroll position
  const [userCredits, setUserCredits] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false); // Added state for dropdown

  useEffect(() => {
    if (!user) {
      return;
    }
    console.log(user);
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY); // Update scrollY state
    };

    window.addEventListener('scroll', handleScroll); // Add scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    setUserCredits(user?.credits || 0);
  }, [user?.credits]);

  return (
    <>
      <nav style={{ zIndex: 100 }} className={`navbar ${scrollY > 0 ? 'navbar-transparent' : ''}`}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="logo" style={{ maxHeight: '40px', width: 'auto' }} />
          </Link>

          <div className="flex items-center gap-4">
            {user && (
              <div className="flex items-center text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 text-lime-400" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 011-1h4a1 1 0 010 2H8a1 1 0 01-1-1zm0 4a1 1 0 011-1h4a1 1 0 010 2H8a1 1 0 01-1-1z" />
                </svg>
                <span className="text-sm font-medium hover:text-lime-400 transition-colors duration-200">
                  {userCredits.toLocaleString()}
                </span>
              </div>
            )}

            <div className="relative flex items-center gap-2 text-lg">
              <button className="flex items-center overflow-hidden rounded-md focus:outline-none p-1 hover:bg-zinc-600" onClick={() => setShowDropdown(!showDropdown)}>
                {!user && (
                  <div className="text-white text-sm text-transform: uppercase p-2">Sign In</div>
                )}

                {user && (
                  <img src={user.photoURL ? user.photoURL : "https://i.imgur.com/BjvdtKN.png"} alt="User" className="w-8 h-8 object-cover" />
                )}
              </button>

              {showDropdown && (
                <div className="absolute right-0 top-0 w-48 mt-[50px] bg-zinc-600 text-white rounded-md shadow-lg py-2 p-2">
                  {user ? (
                    <>
                      <div>
                        <Link to="/profile">
                          <button onClick={() => setShowDropdown(false)} className="block w-full hover:bg-zinc-500 px-4 py-2 text-sm text-left">
                            Profile
                          </button>
                        </Link>

                        <Link to="/community">
                          <button onClick={() => setShowDropdown(false)} className="block w-full hover:bg-zinc-500 px-4 py-2 text-sm text-left">
                            Community
                          </button>
                        </Link>

                        <Link to="/generator">
                          <button onClick={() => setShowDropdown(false)} className="block w-full font-bold hover:bg-zinc-500 px-4 py-2 text-sm text-left">
                            Create
                          </button>
                        </Link>

                        <button className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-700 bg-red-700 text-white font-bold border-radius-4" onClick={clickSignOut}>
                          Sign out
                        </button>
                      </div>
                    </>
                  ) : (
                    <div>
                      <button className="block w-full text-left px-4 py-2 text-sm hover:bg-zinc-500" onClick={clickSignIn}>
                        Login
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
