import React, { useState, useEffect, useRef } from 'react';
import { fetchUsers } from '../database'; // Assume this function now returns users with their saved emotes

export default function Community() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const loadUsers = async () => {
      // Fetch up to 20 users from the database, including their saved emotes
      const fetchedUsers = await fetchUsers(20);
      let emoteChain = [];
      for (let i = 0; i < fetchedUsers.length; i++) {
        for (let j = 0; j < fetchedUsers[i].emotes.length; j++) {
          let user = {
            username: fetchedUsers[i].name,
            imageUrl: fetchedUsers[i].emotes[j].imageUrl
          }
          emoteChain.push(user);
        }
      }
      const emotes = await Promise.all(emoteChain);
      setUsers(emotes);
    };

    loadUsers();
  }, []);

  const canvasRef = useRef(null);

  const drawImageToCanvas = (imageUrl, username, canvasElement) => {
    const ctx = canvasElement.getContext('2d');
    const img = new Image();
    img.crossOrigin = "Anonymous";  // This allows loading images from other domains
    img.onload = () => {
      // Set canvas size (adjust as needed)
      canvasElement.width = 200;
      canvasElement.height = 200;

      // Draw image at lower resolution
      ctx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);

      // Add username overlay
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fillRect(0, 0, canvasElement.width, canvasElement.height);
      ctx.fillStyle = 'white';
      ctx.font = '20px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(username, canvasElement.width / 2, canvasElement.height / 2);
    };
    img.src = imageUrl;
  };

  return (
    <div className="community container mx-auto px-4 py-8 mt-20">
      <h1 className="text-3xl font-bold mb-8 text-center text-white">Community</h1>
      <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-4">
        {users.map((emote, index) => (
          <div className='grid rounded-lg p-3 hover:scale-105 transition-all duration-300 hover:bg-gray-800' key={index}>
            <canvas
              ref={(el) => {
                if (el) {
                  drawImageToCanvas(emote.imageUrl, emote.username, el);
                }
              }}
              className="w-full h-auto rounded-lg"
            ></canvas>
          </div>
        ))}
      </div>
    </div>
  );
}
